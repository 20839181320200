<template>
  <div class="favorite-list">
    <input
      v-model="input"
      type="checkbox"
      @click="invokeFilter"
      :id="favorite.id"
    />
    <label class="ml-2 mb-0 mr-2" :for="favorite.id">
      {{ favorite.name }}
    </label>
    <div class="ml-auto cursor-pointer" @click.prevent.stop="deletePopup()">
      <TrashIcon />
    </div>
    <FavoritesPopup
      :popup="popup"
      :favorite="favorite"
      @closePopup="closePopup"
      @confirmPopup="deleteSelected"
    />
  </div>
</template>

<script>
import ls from '@/utils/locale-storage';
import TrashIcon from '@/components/icons/Trash';
import FavoritesPopup from '@/components/base-components/Favorites/Popup';

import { mapActions } from 'vuex';

export default {
  name: 'SideBarUpcomingListFavourite',
  components: {
    TrashIcon,
    FavoritesPopup,
  },
  props: {
    favorite: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      input: false,
      popup: false,
    };
  },
  mounted() {
    this.rememberList = ls.get('eventsRememberList') || [];
    if (this.rememberList?.length > 0) {
      //this.input = this.rememberList.some(rememberItem => rememberItem.id === this.favorite.favorite_id);
      this.input =
        this.rememberList &&
        this.favorite &&
        this.rememberList.some(
          (rememberItem) =>
            rememberItem.id &&
            rememberItem.id === this.favorite.favorite_id &&
            rememberItem.type &&
            rememberItem.type === this.favorite.favorite_type
        );
    }
  },
  methods: {
    ...mapActions(['GET_DELETE_FAVORITE']),
    deletePopup() {
      this.popup = !this.popup;
    },
    closePopup() {
      this.popup = false;
    },
    deleteSelected() {
      this.GET_DELETE_FAVORITE({
        favorite_type: this.favorite.favorite_type,
        id: this.favorite.favorite_id,
      });
      this.closePopup();
      if(this.input){
        this.invokeFilter()
      }
    },
    invokeFilter() {
      //this.$store.dispatch('updateSelectedFavorites', newSelectedFavorites);
      this.$emit('invokedInput', {
        id: this.favorite.favorite_id,
        position: this.position,
        input: !this.input,
      });
      // let query = { ...this.$route.query };
      // if(this.favorite.channel_id)
      // check route URL for existing ID, remove or replace or add
    },
  },
};
</script>

<style scoped lang="scss">
.favorite-list {
  text-align: start !important;
  margin-bottom: 1.5rem;
  display: flex;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
